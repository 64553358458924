@import "common/var";
#top{

	//mv -----------------------------------------------
	.mv{
		position: relative;
		z-index: 2;

		.inner{
			width: 100%;
		}

		&__img{
			img{
				width: 100%;
			}
		}

		&__txt{
			position: absolute;
			left: 0;
			top: 0;
			padding: percentage(324px/$contents-width--sp) 0 0 percentage(40px/$contents-width--sp);

			.jp{
				font-size: 20px;
				letter-spacing: 0.06em;
				font-weight: bold;
				color: $color-white;
				display: block;
				text-align: left;
				line-height: percentage(36/20);
			}

			.en{
				font-size: 10px;
				letter-spacing: 0.06em;
				font-weight: bold;
				color: $color-white;
				display: block;
				text-align: left;
				line-height: percentage(36/20);
				margin-top: -2px;
			}
		}

		&__bg1{
			position: absolute;
			left: -11px;
			bottom: -25px;
			width: 63px;
		}
	}


	//about -----------------------------------------------
	.about{

		&__wrap{
			background: #f7f5e5;
		}

		&__catch{
			font-size: 15px;
			letter-spacing: 0.1em;
			text-indent: 0.1em;
			font-weight: bold;
			line-height: percentage(68/30);
			margin: 38px auto 46px auto;
			text-align: center;
		}

		.inner{
			padding: 51px 0 50px 0;
			position: relative;

			.bg1{
				position: absolute;
				left: -10px;
				bottom: 112px;
				width: 58px;
			}

			.img1{
				position: absolute;
				left: -35px;
				top: 41px;
				width: 97px;
			}

			.img2{
				position: absolute;
				right: -27px;
				top: 61px;
				z-index: 1;
				width: 72px;
			}

			.img3{
				position: absolute;
				right: -54px;
				bottom: -7px;
				width: 102px;
			}
		}

		&__content__wrap{
			padding: 80px 0;
		}

		&__content{
			width: percentage(670px/$contents-width--sp);
			margin: 0 auto;
			padding-bottom: 60px;
			position: relative;

			&:last-of-type{
				padding-bottom: 0;
			}

			.img{
				width: 100%;
				margin-bottom: 9px;

				img{
					width: 100%;
				}
			}

			.detail{
				width: 100%;

				.ttl{
					font-size: 18px;
					letter-spacing: 0.1em;
					line-height: percentage(68/36);
					margin-bottom: 3px;
					font-weight: bold;
					text-align: left;
				}

				.txt{
					font-size: 14px;
					line-height: percentage(48/28);
					margin-bottom: 16px;
					text-align: left;
				}

				.btn-wrap{
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
				}
			}

			.bg2{
				position: absolute;
				right: -27px;
				top: -25px;
				z-index: 2;
				width: 70px;
			}

			.bg3{
				position: absolute;
				left: 45px;
				top: -38px;
				z-index: 2;
				width: 63px;
			}
		}
	}


	//info -----------------------------------------------
	.info{
		padding: 50px 0 50px 0;
		background: #f7f5e5;
		box-sizing: border-box;
		position: relative;

		.section-heading{
			margin-bottom: 30px;
		}

		&__list{
			font-size: 14px;
			letter-spacing: 0.06em;
			text-align: center;
			border-bottom: 1px dotted #acaba0;
			padding-bottom: 28px;

			&:last-of-type{
				margin-bottom: 0;
				border-bottom: none;
				padding-bottom: 0;
			}

			dt{
				display: block;
				width: 100%;
				margin-bottom: 13px;
				text-align: left;
			}

			dd{
				@include txt_link_opacity;
				text-align: left;

				a{
					color: $color-green;
					display: block;
					margin-top: 6px;
				}
			}
		}

		.btn-type1{
			margin-top: 47px;
		}

		.bg1{
			position: absolute;
			right: 38px;
			top: -80px;
			width: 76px;
		}
	}
}
//top
