// ---------------------------------
$color-black: #000;
$color-white: #fff;
$color-yellow: #ff0;
$color-green: #0fa98e;
$default-color: $color-black;
$contents-width--sp: 750px;
$contents-width--sp--inner: 670px;
$font-en: 'Lato', sans-serif;

// ---------------------------------
@mixin txt_link_opacity($deco:none, $color:$default-color, $hover-deco:none) {
	a,
	a:link,
	a:hover,
	a:visited {
		color: $color;
		text-decoration: $deco;
		@include transition(.2);
	}
}

@mixin txt_link_color($deco:none, $color:$default-color, $hover-deco:none) {
	a,
	a:link,
	a:hover,
	a:visited {
		color: $color;
		@include transition(.2);
		text-decoration: $deco;
	}
}

@mixin cf {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin transition($time:.2, $prop:all, $easing:ease-in-out) {
	transition: $prop #{$time}s $easing;
}

// scrollbar ---------------------------------------------
@mixin scrollbar {
	padding-bottom: 10px!important;

	&::-webkit-scrollbar{
		width: 6px;
	}

	&::-webkit-scrollbar:horizontal{
		height: 5px;
	}

	&::-webkit-scrollbar-thumb{
		background: #ccc;
		border-radius: 3px;
	}

	&::-webkit-scrollbar-thumb:horizontal{
		background: #ccc;
		border-radius: 3px;
	}
}
