#top .mv {
  position: relative;
  z-index: 2;
}

#top .mv .inner {
  width: 100%;
}

#top .mv__img img {
  width: 100%;
}

#top .mv__txt {
  position: absolute;
  left: 0;
  top: 0;
  padding: 43.2% 0 0 5.33333%;
}

#top .mv__txt .jp {
  font-size: 20px;
  letter-spacing: 0.06em;
  font-weight: bold;
  color: #fff;
  display: block;
  text-align: left;
  line-height: 180%;
}

#top .mv__txt .en {
  font-size: 10px;
  letter-spacing: 0.06em;
  font-weight: bold;
  color: #fff;
  display: block;
  text-align: left;
  line-height: 180%;
  margin-top: -2px;
}

#top .mv__bg1 {
  position: absolute;
  left: -11px;
  bottom: -25px;
  width: 63px;
}

#top .about__wrap {
  background: #f7f5e5;
}

#top .about__catch {
  font-size: 15px;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  font-weight: bold;
  line-height: 226.66667%;
  margin: 38px auto 46px auto;
  text-align: center;
}

#top .about .inner {
  padding: 51px 0 50px 0;
  position: relative;
}

#top .about .inner .bg1 {
  position: absolute;
  left: -10px;
  bottom: 112px;
  width: 58px;
}

#top .about .inner .img1 {
  position: absolute;
  left: -35px;
  top: 41px;
  width: 97px;
}

#top .about .inner .img2 {
  position: absolute;
  right: -27px;
  top: 61px;
  z-index: 1;
  width: 72px;
}

#top .about .inner .img3 {
  position: absolute;
  right: -54px;
  bottom: -7px;
  width: 102px;
}

#top .about__content__wrap {
  padding: 80px 0;
}

#top .about__content {
  width: 89.33333%;
  margin: 0 auto;
  padding-bottom: 60px;
  position: relative;
}

#top .about__content:last-of-type {
  padding-bottom: 0;
}

#top .about__content .img {
  width: 100%;
  margin-bottom: 9px;
}

#top .about__content .img img {
  width: 100%;
}

#top .about__content .detail {
  width: 100%;
}

#top .about__content .detail .ttl {
  font-size: 18px;
  letter-spacing: 0.1em;
  line-height: 188.88889%;
  margin-bottom: 3px;
  font-weight: bold;
  text-align: left;
}

#top .about__content .detail .txt {
  font-size: 14px;
  line-height: 171.42857%;
  margin-bottom: 16px;
  text-align: left;
}

#top .about__content .detail .btn-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#top .about__content .bg2 {
  position: absolute;
  right: -27px;
  top: -25px;
  z-index: 2;
  width: 70px;
}

#top .about__content .bg3 {
  position: absolute;
  left: 45px;
  top: -38px;
  z-index: 2;
  width: 63px;
}

#top .info {
  padding: 50px 0 50px 0;
  background: #f7f5e5;
  box-sizing: border-box;
  position: relative;
}

#top .info .section-heading {
  margin-bottom: 30px;
}

#top .info__list {
  font-size: 14px;
  letter-spacing: 0.06em;
  text-align: center;
  border-bottom: 1px dotted #acaba0;
  padding-bottom: 28px;
}

#top .info__list:last-of-type {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

#top .info__list dt {
  display: block;
  width: 100%;
  margin-bottom: 13px;
  text-align: left;
}

#top .info__list dd {
  text-align: left;
}

#top .info__list dd a,
#top .info__list dd a:link,
#top .info__list dd a:hover,
#top .info__list dd a:visited {
  color: #000;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

#top .info__list dd a {
  color: #0fa98e;
  display: block;
  margin-top: 6px;
}

#top .info .btn-type1 {
  margin-top: 47px;
}

#top .info .bg1 {
  position: absolute;
  right: 38px;
  top: -80px;
  width: 76px;
}
